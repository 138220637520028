import { buildSelectOptions } from '@utils/dropdown'
import { orderBy } from 'lodash' // For UI prototyping purposes

const prepareDropdown = (items, defaultFilterItem) => {
  const selectItem = {
    key: 'id',
    value: 'id',
    text: 'name',
    name: 'id'
  }
  let itemsOutput = orderBy(items, ['displayOrder'], ['asc'])
  itemsOutput = buildSelectOptions(itemsOutput, selectItem)
  itemsOutput = itemsOutput.filter(item => item.text !== undefined && item.text !== '')
  itemsOutput = orderBy(itemsOutput, ['displayOrder'], ['asc'])
  itemsOutput.unshift(defaultFilterItem)
  return itemsOutput
}

export default prepareDropdown