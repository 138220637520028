import { FC, useContext } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash' // For UI prototyping purposes
import Observer from '@researchgate/react-intersection-observer'

// import Slider from '@reusable-modules/common/SliderV2'
import SliderWrapper from './Slider'
import { StoryCard, Container, Row, Col, Icon } from '@mch-group/uikit-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary, date } from '@constants'
import { detectDpr, formatArticleLink, formatDate } from '@utils'
import { CustomLinkTag } from '@components'
import classNames from 'classnames'
import NoMarginTopWhenInWebView from '@components/StyleNoMarginTopWhenInWebView'

import cssClasses from '../../../constants/cssClasses'
import Article from './Article'
import NoResultsComponent from '../../NoResults'

import useInitNews from './hooks/useInitNews'
import StateContext from './ContextProvider/Context'
import Filters from './Filters'

const NewsModule: FC<INewsModule> = () => {
  const { displayVideoIcon, isInfiniteLayout, apiLabels } = useInitNews()
  const {
    state: {
      filterCategoryOn,
      filterProductOn,
      layout,
      labels,
      selectedCategory,
      selectedProduct,
      news,
      isLoading,
      hasNews
    },
    refs
  } = useContext(StateContext)

  if (layout === 'carousel' && hasNews) return <SliderWrapper />
  return (
    <>
      <NewsModuleStyled
        className={classNames(
          labels.className,
          cssClasses.CMS_MODULE,
          ' no-margin-top-mobile-web-view ',
          layout === '4' ? ' hero ' : '')}
      >

        <>
          {layout === '4' && hasNews && <Article />}
          <Container className='px-5 px-lg-8 px-md-7'>
            {layout !== '4' && !isEmpty(labels.news_grp1_title) && (
              <Row className='gx-lg-8 gx-md-7 mb-xs-8'>
                <Col className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-lg-8 mb-md-7 mb-6'>
                  <h2 className='h3'>{labels.news_grp1_title}</h2>
                </Col>
              </Row>
            )}
            {(filterCategoryOn || filterProductOn) && <Filters />}
            {news.length > 0 && (
              <Row className='gx-lg-8 gx-md-7 mb-xs-8'>
                {news.map((item, index) => {
                  const newsLink = item.redirectUrl ? item.redirectUrl : formatArticleLink(item.url)
                  const title = (labels.news_grp1_flag_title === 'true' && item.title) || ''
                  // This part getting complicated because of lack of a Product/Categoryspecific field in UIKit
                  // So author and Product/Category had to be merged in one sole field
                  let subtitle = labels.news_grp1_flag_author === 'true' && item.authorName || ''
                  if (item.categoryName || item.productName) {
                    if (item.categoryName && labels.news_grp1_flag_category === 'true' && item.categoryName) {
                      subtitle = `${subtitle}${subtitle && ', '}${item.categoryName}${(item.subProduct ? `:  ${item.subProduct} ` : '')}`
                    }
                    if (item.productName && labels.news_grp1_flag_product === 'true') {
                      subtitle = `${subtitle}${subtitle && ' - '}${item.productName}`
                    }
                  }
                  const description = (labels.news_grp1_flag_description === 'true' && item.description) || ''
                  const newsDate = (labels.news_grp1_flag_publish_date === 'true'
                    && item.publishedDate
                    && formatDate(item.publishedDate, 'YYYY-MM-DD', date.FE_WEEK_DATE_FORMAT)) || ''

                  const linkRenderer = (link, linkTitle, children) => (
                    <CustomLinkTag
                      title={`${apiLabels?.AltTags?.generalReadMoreAbout} ${linkTitle}`}
                      url={item.redirectUrl ? item.redirectUrl : formatArticleLink(item.url)}
                    >
                      {children}
                    </CustomLinkTag>
                  )
                  const buttonLink = labels.news_grp1_label_call_to_action ? {
                    type: 'textlink',
                    label: labels.news_grp1_label_call_to_action
                  } : {}
                  const articleKey = `news_article_${item.id}_${selectedCategory}_${selectedProduct}`
                  // If Layout is type 4, ignore the first result in the loop
                  // because it already shows on top (hero)
                  if (layout === '4' && index === 0) return null
                  if ((layout === '2n') || (layout === '2')) {
                    return (
                      <Col className='mb-lg-9 mb-md-9 mb-8 col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12' key={articleKey}>
                        <StoryCard
                          eyebrow={subtitle}
                          title={title}
                          alt={`${apiLabels?.AltTags?.generalReadMoreAbout} ${title}`}
                          description={description}
                          subtitle={newsDate}
                          image={item.imageUrl && uploadTransformationUrl(
                            item.imageUrl,
                            `w_800,q_auto,${detectDpr()},ar_1.6,c_crop,c_fill,g_auto`,
                            { transformUrl: cloudinary.throughCloudfront }
                          )}
                          linkRenderer={linkRenderer}
                          //@ts-ignore
                          button={buttonLink}
                          storyLink={newsLink}
                          equalHeight
                          video={item?.tags ? displayVideoIcon(item?.tags) : false}
                        />
                      </Col>
                    )
                  }
                  return (
                    <Col className='mb-lg-9 mb-md-9 mb-8 col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12' key={articleKey}>
                      <StoryCard
                        eyebrow={subtitle}
                        title={title}
                        alt={`${apiLabels?.AltTags?.generalReadMoreAbout} ${title}`}
                        description={description}
                        image={item.imageUrl && uploadTransformationUrl(
                          item.imageUrl,
                          'w_510,q_auto,dpr_1.0,ar_1.6,c_crop,c_fill,g_auto,f_auto',
                          { transformUrl: cloudinary.throughCloudfront }
                        )}
                        subtitle={newsDate}
                        linkRenderer={linkRenderer}
                        //@ts-ignore
                        button={buttonLink}
                        storyLink={newsLink}
                        equalHeight
                        video={item?.tags ? displayVideoIcon(item?.tags) : false}
                      />
                    </Col>
                  )
                })}
              </Row>
            )}
          </Container>

          {/* View all stories / news CTA */}
          {labels.news_grp1_more && labels.news_grp1_href_more && hasNews && (
            <Container className='px-5 px-lg-8 px-md-7'>
              <CustomLinkTag url={labels.news_grp1_href_more} className='icon-right btn btn-link'>
                <Icon name='chevron-right' size={24} />
                {labels.news_grp1_more}
              </CustomLinkTag>
            </Container>
          )}

          {/* No results message coming from CMS New Module */}
          {!hasNews && !isLoading && (
            <NoResultsComponent entityid='PressContent' fieldName='noResultsWarning' isNested />
          )}
          {isInfiniteLayout && hasNews && (
            <Observer onChange={() => {
              // @ts-ignore // Difficulties typing refs here
              refs.offset.current = refs.offset.current + 3
              // @ts-ignore
              refs.loadNextPage.current()
            }} threshold={0.01} rootMargin='200px' disabled={!hasNews}>
              {/* If the image gets within 100px above, start the download.
                   * The is a cheap hack to detect distance to image since "rootMargin" doesn't work */}
              <div style={{ width: '100%', height: '1px' }} />
            </Observer>
          )}
        </>
      </NewsModuleStyled>
      <NoMarginTopWhenInWebView />
    </>
  )
}

interface INewsModule {
  className?: string
}

const NewsModuleStyled = styled.div`
  &:first-child {
    @media (max-width: ${global.uikitVars.breakpointMdMax}) {
      margin-top: 135px;
    }

    @media (max-width: ${global.uikitVars.breakpointSmMax}) {
      margin-top: 90px;
    }

    @media (min-width: ${global.uikitVars.breakpointLg}) {
      margin-top: 50px;
    }

    &.hero {
      margin-top: 0;
    }
  }

  .rm-news {
    &__filter {
      @media (max-width: ${global.uikitVars.breakpointMdMax}) {
        width: 100%;
      }
    }

    .field + .field {
      @media (max-width: ${global.uikitVars.breakpointMdMax}) {
        margin-top: 20px;
      }
    }
  }
`

export default NewsModule
