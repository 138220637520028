import { createGlobalStyle } from 'styled-components'

const NoMarginTopWhenInWebView = createGlobalStyle`
  .is-mobile-web-view {
    .no-margin-top-mobile-web-view {
      @media (max-width: ${MFP.vars.largestMobileScreen}) {
        margin-top: 0 !important;
      }
    }
  }
`

export default NoMarginTopWhenInWebView
