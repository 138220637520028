import { createContext } from 'react'

export const reducer = (state, action) => {
  // review which can be removed
  switch (action.type) {
    case 'SET_NEWS':
      return {
        ...state,
        news: action.payload
      }
    case 'IS_INITIALIZED':
      return {
        ...state,
        initialized: action.payload
      }
    case 'HAS_NEWS':
      return {
        ...state,
        hasNews: action.payload
      }
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.payload
      }
    case 'SET_SELECTED_CATEGORY':
      return {
        ...state,
        selectedCategory: action.payload
      }
    case 'SET_SELECTED_PRODUCT':
      return {
        ...state,
        selectedProduct: action.payload
      }
    case 'SET_ISLOADING':
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return { ...state }
  }
}

export const initialState = {
  CMSProps: null
}

type StateType = {
  news: Array<ABTypes.Monolith.NewSearchResult>,
  randomStr: string,
  selectedCategory: string,
  selectedProduct: string,
  limit: number,
  layout?: string,
  initialized: boolean,
  filterCategoryOn?: boolean,
  filterProductOn?: boolean,
  filters?: {
    categories: any,
    products: any
  },
  pageSize: number,
  hasNews?: boolean,
  mID?: string,
  isLoading?: boolean,
  labels: {
    [labelKey: string]: string
  }
}

export const StateContext = createContext<{
  state: StateType
  refs: {
    handleChangeCategory?: React.RefObject<(event: any, reqData: any) => any>,
    handleChangeProduct?: React.RefObject<(event: any, reqData: any) => any>,
    loadNextPage?: React.RefObject<() => void>,
    offset?: React.RefObject<number | null>,
    paginationInfo?: React.RefObject<{
      pageToken?: string
    }>,
  }
  dispatch: React.Dispatch<any>
}>({
  state: {
    news: [],
    labels: {},
    randomStr: '',
    selectedCategory: '0',
    selectedProduct: '0',
    limit: 3,
    initialized: false,
    pageSize: 27
  },
  refs: {
    offset: { current: 0 }
  },
  dispatch: () => null
})

export default StateContext
