import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getTranslationByName, px2rem, hiDPIpx } from '../utils'
import Text from './Text'
import { getSiteLanguage } from '../utils/storage'
import mq from '../utils/styled-mq'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const NoResultsWrapper = styled.section`
  margin-top: ${px2rem(62)};
  ${mq.between('tablet', 'largestTablet')`
    font-size: ${hiDPIpx(55)};
  `}
  ${mq.lessThan('largestMobile')`
    font-size: ${tokens.fontSize[14].value};
  `}

  .main-text {
    ${mq.lessThan('largestTablet')`
    
      ${props =>
        // @ts-ignore
        props.isNested && 'margin: 0 !important;'
      }
    `}

    div {
      font-size: ${tokens.fontSize[5].value};
      ${mq.between('tablet', 'largestTablet')`
        font-size: ${hiDPIpx(20)};
      `}
      ${mq.lessThan('largestMobile')`
        font-size: ${tokens.fontSize[4].value};
      `}
    }
  }
`

const NoResultsComponent = ({ className, isNested, entityid, fieldName, labels, customText }) => (
  // @ts-ignore
  <NoResultsWrapper isNested={isNested}>
    <Container className={`main-text ${className}`}>
      <Text isStrong>
        { entityid && fieldName ? getTranslationByName(labels, getSiteLanguage(), entityid, fieldName) : customText}
      </Text>
    </Container>
  </NoResultsWrapper>
)

NoResultsComponent.propTypes = {
  className: PropTypes.string,
  entityid: PropTypes.string,
  fieldName: PropTypes.string,
  labels: PropTypes.object,
  customText: PropTypes.string,
  isNested: PropTypes.bool
}

export default connect(
  ({ shared }) =>
    ({
      labels: shared.labels
    }),
  null
)(NoResultsComponent)
