import { useContext } from 'react'

import Slider from '@reusable-modules/common/SliderV2'

import cssClasses from '../../../constants/cssClasses'
import StateContext from './ContextProvider/Context'

const SliderWrapper = () => {
  const {
    state: {
      labels,
      news
    }
  } = useContext(StateContext)
  return (
    <Slider
      slides={news}
      className={`${cssClasses.CMS_MODULE} no-margin-top`}
      linkAtBottomHref={labels.news_grp1_href_more}
      linkAtBottomLabel={labels.news_grp1_more}
      labelItemCTA={labels.news_grp1_label_call_to_action}
      flagSlideTitle={labels.news_grp1_flag_title === 'true'}
      flagSlideAuthor={labels.news_grp1_flag_author === 'true'}
      flagSlidePublishDate={labels.news_grp1_flag_publish_date === 'true'}
      flagSlideProduct={labels.news_grp1_flag_publish_date === 'true'}
      flagSlideCategory={labels.news_grp1_flag_category === 'true'}
      background={labels.news_grp1_carousel_background === '1' ? 'dark' : 'white'}
      slideTextTitle={labels.news_grp1_title}
      timePerSlide={5} // In Seconds
    />
  )
}

export default SliderWrapper