import { FC } from 'react'

import Provider from './ContextProvider/Provider'
import Main from './main'

/* -- Quick schema --

  index -> Main ->  Filters.tsx
                    Article.tsx
                    Slider.tsx

*/


const NewsModule: FC<INewsModule> = (props) => <Provider {...props}><Main /></Provider>

interface INewsModule {
  className: string
}


export default NewsModule
