import { useContext } from 'react'
import { Form } from 'semantic-ui-react'
import { Row, Col } from '@mch-group/uikit-components'
import StateContext from './ContextProvider/Context'

const Filters = () => {
  const {
    state: {
      filterCategoryOn,
      filterProductOn,
      filters,
      labels,
      selectedCategory,
      selectedProduct,
      mID,
      isLoading
    },
    refs
  } = useContext(StateContext)
  return (
    <Row className='gx-lg-8 gx-md-7 mb-xs-8'>
      <Col className='mb-lg-6 mb-md-6 mb-6 col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
        <Form>
          <Form.Group>
            {/* This lines switches filter, depending on CMS */}
            {filterCategoryOn && filters && filters.categories?.length > 0 && (
              // TODO: Move to Dropdown UIKIT
              <Form.Select
                className={`${mID}__filter`}
                label={labels.news_grp1_category_lbl || 'Category'}
                name='category'
                options={filters.categories}
                // @ts-ignore
                onChange={refs.handleChangeCategory.current}
                disabled={isLoading}
                value={parseInt(selectedCategory, 10)}
              />
            )}

            {/* This lines removes switches filter, depending on CMS */}
            {filterProductOn && filters && filters.products?.length > 0 && (
              <Form.Select
                className={`${mID}__filter`}
                label={labels.news_grp1_product_lbl || 'Product'}
                name='product'
                options={filters?.products}
                // @ts-ignore
                onChange={refs.handleChangeProduct.current}
                disabled={isLoading}
                value={parseInt(selectedProduct, 10)}
                aria-label={labels.news_grp1_product_lbl || 'Product'}
              />
            )}
          </Form.Group>
        </Form>
      </Col>
    </Row>
  )
}

export default Filters