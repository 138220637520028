import { FC, useMemo, useReducer, useRef } from 'react'
import { isOkForHydration } from '@utils'
import { environment } from '@dmi-mch/utils'
import shortid from 'shortid'

import { reducer, StateContext } from './Context'
import defineLimit from '../utils/defineLimit'

const ModuleProvider: FC = (props) => {
  const { children, ...rest }: any = props
  // ssrData fields are received from NextJS server load.
  const itemsInSSR = rest.ssrData.SSRNewsList?.constructor.name == 'Array'
  const initialState = useMemo(() => ({
    CMSProps: rest.CMSProps, // static
    news: itemsInSSR ? [...rest.ssrData.SSRNewsList] : [],
    hasNews: itemsInSSR && rest.ssrData.SSRNewsList.length > 0,
    // layout choices: "3, 3n, 4 and Carousel"
    layout: rest.ssrData.SSRNewsLayout || rest.news_grp1_article,
    filterCategoryOn: rest.news_grp1_flag_filter_category === 'true', // static
    filterProductOn: rest.news_grp1_flag_filter_product === 'true', // static
    filters: {},
    selectedCategory: rest.news_grp1_default_category ? rest.news_grp1_default_category : 0,
    selectedProduct: rest.news_grp1_default_product ? rest.news_grp1_default_product : 0,
    labels: rest, // static
    mID: 'rm-news', // static
    isLoading: false,
    limit: defineLimit(rest.news_grp1_article), // static
    initialized: false,
    randomStr: rest.ssrData.SSRNewsRandomStr || shortid.generate(),
    pageSize: 27
  }), [itemsInSSR, rest])

  const refs = {
    // review which can be removed
    handleChangeCategory: useRef(() => { }),
    handleChangeProduct: useRef(() => { }),
    loadNextPage: useRef(() => { }),
    paginationInfo: useRef({
      pageToken: itemsInSSR && rest.ssrData.SSRNewsFirstPagetoken
    }),
    offset: useRef(itemsInSSR ? 3 : 0)
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useMemo(() => {
    if (isOkForHydration(state, initialState)) {
      environment.isNotPro &&
        // eslint-disable-next-line no-console
        console.error(
          'Attention, the initial state configured here and the one declared in' +
          ' ../contexts/module does not match. Remember to update and to add the explanation of what is, what for, ' +
          'and where is used, inside module context file.'
        )
    }
  }, [initialState, state])

  return (
    <StateContext.Provider value={{ state, dispatch, refs }}>{children}</StateContext.Provider>
  )
}

export default ModuleProvider