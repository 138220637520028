import PropTypes from 'prop-types'
import styled from 'styled-components'
import Slider from 'react-slick'
import Link from 'next/link'

import { mq, px2rem, hiDPIpx, cmsPageUrl, formatArticleLink, isDetailLink } from '@utils'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import CallToAction from '@reusable-modules/common/CallToAction'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const SliderV2 = ({
  className,
  slides,
  linkAtBottomHref,
  linkAtBottomLabel,
  labelItemCTA,
  slideTextTitle,
  flagSlideTitle,
  flagSlideProduct,
  flagSlideCategory,
  timePerSlide = 8

}) => {
  const transformationsLargeImage = 'q_auto'

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    // To respect CSS width
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    adaptativeHeight: false,
    autoplaySpeed: timePerSlide * 1000,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1535,
        settings: {
          variableWidth: false
        }
      },
      {
        breakpoint: 1536,
        settings: {
          variableWidth: true
        }
      }
    ],
    autoplay: slides.length > 1 && true,
    // The default rendered dots don't have enough layers to vertically centering. This helps
    appendDots: dots => (
      <div>
        <div>
          <ul>{dots}</ul>
          {linkAtBottomHref && linkAtBottomLabel &&
            <ViewAllButtonStyled>
              <span>|</span>
              <CallToAction
                hideArrow
                bold
                textDecoration
                href={isDetailLink(linkAtBottomHref, 'news', '/')}
                as={linkAtBottomHref}
              >
                {linkAtBottomLabel}
              </CallToAction>
            </ViewAllButtonStyled>
          }
        </div>
      </div>
    )
  }

  return (
    <SliderStyled className={className} {...settings} slidesNum={slides.length}>
      {slides && slides.map((slide) => (
        <SlideContainer key={slide.id}>
          <SlideImage
            style={{
              backgroundImage:
                `url(${uploadTransformationUrl(slide.featureImageUrl, transformationsLargeImage, { 'transformUrl': cloudinary.throughCloudfront }) || slide.imageUrl})`
            }}
          />
          <SlideInfo>
            <p className='pre-title'>
              <b className='color-textgrey'>
                {slideTextTitle}
                {flagSlideCategory && <span>{slide.categoryName}</span>}
                {flagSlideProduct && <span>{slide.productName}</span>}
              </b>
            </p>
            {flagSlideTitle && <p className='title ui header h1 color-black'>{slide.title}</p>}
            <Link
              href={cmsPageUrl(`${slide.redirectUrl ? slide.redirectUrl : slide.url}`,
                '/', { cleanTitle: slide.url })}
              as={slide.redirectUrl ? slide.redirectUrl : formatArticleLink(slide.url)}
              passHref
              legacyBehavior
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className='link color-black'><b>{labelItemCTA}</b></a>
            </Link>

          </SlideInfo>
        </SlideContainer>
      ))}
    </SliderStyled>
  )
}

const ViewAllButtonStyled = styled.div`
  pointer-events: auto;
  display: flex;

  > span {
    margin: 0 ${px2rem(13)};
  }
`

const SliderStyled = styled(Slider)`
  /* This is a bit crazy, but works. It resets the slide width variable depending on mediaquery */
  @media (min-width: 1480px) {
    --desktop-width: ${px2rem(1480)};
  }

  @media (min-width: 1480px) and (max-width: 1679px) {
    --desktop-width: ${px2rem(1460)};
  }

  @media (min-width: 1200px) and (max-width: 1479px) {
    --desktop-width: ${px2rem(1200)};
  }

  --desktop-cards-separation: ${px2rem(24)};
  --desktop-card-sides-padding: calc(var(--desktop-cards-separation) / 2);
  --desktop-width-calculated: calc(var(--desktop-width) + var(--desktop-cards-separation));

  /* Classes generated by the Javascript of the Slider */
  /* If there is only one Slide, make a different positioning */
  .slick-track {
    ${props => props.slidesNum === 1 ? `
      display: flex;
      justify-content: center;
    ` : ''}

    .slick-slide {
      width: var(--desktop-width-calculated);
    }
  }

  background: var(--bs-mch-gray-100);

  /* This is a class generated by the slider, so u won't find it in the JSX */
  .slick-dots {
    ${mq.lessThan('largestTablet')`
      position: relative;
      bottom: inherit;
      padding: 0 ${px2rem(20)} ${px2rem(15)} ${px2rem(20)};
    `}
    ${mq.greaterThan('desktop')`
      bottom: ${px2rem(22)};
    `}
    pointer-events: none;
    width: 100%;

    > div {
      ${mq.lessThan('largestTablet')`
        justify-content: flex-start;
      `}
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      @media (min-width: 1536px) {
        width: var(--desktop-width);
      }

      @media (min-width: 1200px) and (max-width: 1535px) {
        padding-right: 40px;
      }

      @media (min-width: 1028px) and (max-width: 1199px) {
        padding-right: ${px2rem(30)};
      }

      @media (min-width: 997px) and (max-width: 1027px) {
        padding-right: ${px2rem(20)};
      }

      @media (max-width: 996px) {
        padding-right: ${px2rem(30)};
      }

      margin: 0 auto;

      ul {
        pointer-events: auto;
        padding: 0;
        text-align: right;
        margin-bottom: 0;

        li {
          margin: 3px;
          width: ${px2rem(9)};
          height: ${px2rem(9)};

          button {
            &::before {
              opacity: 1;
              width: ${px2rem(8)};
              height: ${px2rem(8)};
              border-radius: 100%;
              border: 1px solid var(--bs-mch-black);
              content: '';
            }
          }
        }
      }
    }

    .slick-active {
      button {
        &::before {
          background: var(--bs-mch-black);
        }
      }
    }
  }
`

const SlideContainer = styled.div`
  @media (min-width: 1536px) {
    padding-left: var(--desktop-card-sides-padding);
    padding-right: var(--desktop-card-sides-padding);
  }

  outline: none;
`

const SlideInfo = styled.div`
  ${mq.greaterThan('desktop')`
    width: 60%;
    margin-left: ${px2rem(200)};
    transition: margin 1s ease, opacity 1.2s ease;
  `}

  @media (min-width: 1480px) and (max-width: 1535px) {
    padding: ${px2rem(30)};
  }

  @media (min-width: 1200px) and (max-width: 1479px) {
    padding: ${px2rem(30)};
  }

  @media (min-width: 1028px) and (max-width: 1199px) {
    padding: ${px2rem(30)};
  }

  @media (min-width: 997px) and (max-width: 1027px) {
    padding: ${px2rem(20)};
  }

  @media (max-width: 996px) {
    padding: ${px2rem(20)};
  }

  padding: ${px2rem(20)} 0 ${px2rem(40)};
  opacity: 0;

  .slick-active & {
    opacity: 1;
    margin-left: inherit;
  }

  .pre-title {
    margin-bottom: ${px2rem(10)};
  }

  .title {
    margin-bottom: ${px2rem(20)};
  }

  .link {
    text-decoration: underline;
  }
`

const SlideImage = styled.div`
  ${mq.greaterThan('desktop')`
    height: ${px2rem(448)};
  `}
  ${mq.greaterThan('quadHD')`
    height: ${px2rem(650)};
  `}

  ${mq.between('tablet', 'largestTablet')`
    height: ${hiDPIpx(300)}; /* yes, doesn't correspond to mobile */
  `}
  ${mq.lessThan('largestMobile')`
    height: ${px2rem(280)};
  `}

  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: ${tokens.color.base.primary.black.value};
    display: block;
    transition: opacity 1.2s ease;

    .slick-active & {
      opacity: 0;
    }
  }
`

SliderV2.propTypes = {
  slides: PropTypes.array,
  render: PropTypes.func,
  className: PropTypes.string,
  linkAtBottomHref: PropTypes.string,
  linkAtBottomLabel: PropTypes.string,
  labelItemCTA: PropTypes.string,
  slideTextTitle: PropTypes.string,
  flagSlideTitle: PropTypes.bool,
  flagSlideAuthor: PropTypes.bool,
  flagSlidePublishDate: PropTypes.bool,
  flagSlideProduct: PropTypes.bool,
  flagSlideCategory: PropTypes.bool,
  timePerSlide: PropTypes.number,
  background: PropTypes.string
}

export default SliderV2
